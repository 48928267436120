import React from 'react';

import servicesImg from '../assets/services.png';
import automation from '../assets/automation.jpg';
import plugin from '../assets/plugin.png';
import chatbot from '../assets/chatbot.webp';
import server from '../assets/backend.webp';

const services = [
  {
    title: 'Automation',
    image: automation,
    description:
      'Streamline your processes with tailored automation solutions that save time and increase efficiency.',
  },
  {
    title: 'Chat Bots',
    image: chatbot,
    description:
      'Engage customers effectively with intelligent and interactive chat bots designed for seamless user experiences.',
  },
  {
    title: 'WordPress Plugins',
    image: plugin,
    description:
      'Enhance your website\'s functionality with custom WordPress plugins built to your specifications.',
  },
  {
    title: 'Node / Rust Backend',
    image: server,
    description:
      'Build high-performance, scalable backends in Node.js and Rust to deliver robust, secure, and scalable solutions.',
  },
];

const Services = () => {
  return (
    <div className="flex min-h-screen flex-col items-center animate-fadeIn container mx-auto p-4 bg-white">
      <img
        src={servicesImg}
        alt="Landing Banner"
        className="w-full max-w-4xl g mb-8 mt-16"
        loading="lazy"
      />
      <h3 className="poppins text-2xl underline font-bold mb-4">Services</h3>
      <div className="grid gap-8 grid-cols-1 md:grid-cols-2 w-full max-w-4xl">
        {services.map((service, index) => (
          <div
            key={index}
            className="poppins overflow-hidden flex flex-col items-center text-center p-6 transition-transform transform hover:scale-105"
          >
            <div className="w-32 h-32 mb-4">
              <img
                src={service.image}
                alt={service.title}
                className="w-full h-full rounded-full object-cover"
                loading="lazy"
              />
            </div>
            <h2 className="text-2xl font-bold text-black mb-2">{service.title}</h2>
            <p className="text-black text-base">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
