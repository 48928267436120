import React from 'react';
import privacypolicy from '../assets/policy.png';

const PrivacyPolicy = () => {
  return (
    <div className="flex min-h-screen flex-col items-center animate-fadeIn container mx-auto p-4">
      <img
        src={privacypolicy}
        alt="Landing Banner"
        className="w-full max-w-4xl mb-8 mt-16"
        loading="lazy"
      />
      <h3 className="poppins text-2xl underline font-bold mb-4">Privacy Policy</h3>
      <div className="p-6 max-w-4xl text-gray-700 poppins">
        <h4 className="text-xl font-semibold mb-4">Information We Collect</h4>
        <p className="mb-4">
          We only collect personal information that you voluntarily provide through our contact
          form. This may include:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Your name</li>
          <li>Your email address</li>
          <li>Any other details you share in your message</li>
        </ul>

        <h4 className="text-xl font-semibold mb-4">How We Use Your Information</h4>
        <p className="mb-4">The information you provide is used solely to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Respond to your inquiries and messages.</li>
          <li>Improve our services based on your feedback.</li>
        </ul>
        <p className="mb-4">We do not sell, rent, or share your information with third parties.</p>

        <h4 className="text-xl font-semibold mb-4">Cookies and Tracking</h4>
        <p className="mb-4">
          Our website does not use cookies, analytics, or any tracking technologies.
        </p>

        <h4 className="text-xl font-semibold mb-4">Data Storage and Retention</h4>
        <ul className="list-disc list-inside mb-4">
          <li>
            The information you submit through the contact form is stored securely in our email
            system.
          </li>
          <li>We retain your information only as long as necessary to address your inquiry.</li>
        </ul>

        <h4 className="text-xl font-semibold mb-4">Third-Party Sharing</h4>
        <p className="mb-4">
          We do not share your data with any third-party services, except as required by law.
        </p>

        <h4 className="text-xl font-semibold mb-4">Security Measures</h4>
        <p className="mb-4">
          We take reasonable measures to protect the information you share with us, including secure
          email servers and encryption.
        </p>

        <h4 className="text-xl font-semibold mb-4">Your Rights</h4>
        <p className="mb-4">You have the right to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Request access to the information you have provided.</li>
          <li>Request correction or deletion of your personal data.</li>
        </ul>
        <p className="mb-4">
          To exercise these rights, please contact us at{' '}
          <a
            className="underline"
            href="mailto:team@tailwine.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            team@tailwine.in
          </a>
          .
        </p>

        <h4 className="text-xl font-semibold mb-4">Policy Updates</h4>
        <p className="mb-4">
          We may update this privacy policy from time to time. The latest version will always be
          available on this page.
        </p>

        <h4 className="text-xl font-semibold mb-4">Contact Us</h4>
        <p>
          If you have any questions about this privacy policy or how your data is handled, please
          contact us at:
        </p>
        <ul className="list-disc list-inside mt-4">
          <li>
            <strong>Email</strong>:{' '}
            <a
              className="underline"
              href="mailto:team@tailwine.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              team@tailwine.in
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
