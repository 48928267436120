import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/dist/react';
import CookieBanner from './utils/cookieBanner';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Home, NotFound, Experiments, Contact, Templates, Services, PrivacyPolicy } from './pages';

const AppContent = () => {
  return (
    <>
      <Navbar />
      <CookieBanner />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/oss" element={<Templates />} />
        <Route path="/services" element={<Services />} />
        <Route path="/experiments" element={<Experiments />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <>
      <Router>
        <AppContent />
      </Router>
      <Analytics />
    </>
  );
};

export default App;
