import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import cookieImage from '../assets/cookie.png';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const accepted = localStorage.getItem('cookiesAccepted');
    if (!accepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setIsVisible(false);
  };

  return isVisible ? (
    <div className="fixed bottom-4 right-4 bg-white shadow-lg rounded-lg p-4 flex flex-col md:flex-row items-center space-x-4 space-y-4 md:space-y-0 animate-slideIn z-50 w-[90%] md:w-auto max-w-[350px]">
      <img src={cookieImage} alt="Cookie" className="w-12 h-12 animate-bounce" />
      <div className="flex-1">
        <p className="text-sm text-gray-800">
          We use cookies to improve your experience. By using our site, you consent to our use of
          cookies. For more details, read our{' '}
          <Link to="/privacy-policy" className="underline text-black hover:text-gray-600">
            Privacy Policy
          </Link>
          .
        </p>
        <div className="flex justify-end space-x-2 mt-2">
          <button
            onClick={handleReject}
            className="bg-white text-black hover:bg-black hover:text-white border border-black text-sm py-1 px-3 rounded transition"
          >
            Reject
          </button>
          <button
            onClick={handleAccept}
            className="bg-black text-white hover:bg-white hover:text-black border border-black text-sm py-1 px-3 rounded transition"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default CookieBanner;
