import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Card = ({ title, description, image, link, code }) => {
  return (
    <div
      className="bg-white overflow-hidden transform hover:scale-105 transition-transform duration-300"
      role="article"
      aria-labelledby={title}
    >
      <img src={image} alt={title} className="w-full h-32 sm:h-48 object-cover" loading="lazy" />
      <div className="p-4">
        <h3 id={title} className="text-lg font-bold mb-2">
          {title}
        </h3>
        <p className="text-gray-700 text-sm mb-4">{description}</p>
        {link !== '/' ? (
          <Link
            to={link}
            className="hover:underline text-sm mr-4"
            aria-label={`Learn more about ${title}`}
          >
            Visit
          </Link>
        ) : (
          <></>
        )}
        {code !== '' ? (
          <a
            className="hover:underline text-sm"
            href={code}
            target="_blank"
            rel="noopener noreferrer"
          >
            Code
          </a>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Card;
