import React from 'react';
import Card from '../components/Card';
import landing from '../assets/landing.png';
import orion from '../assets/orion.png';

const pages = [
  {
    title: 'The Orion Framework [Proof of Concept]',
    description: 'Express on Steroids: The Ultimate Plug-and-Play Backend Architecture.',
    image: orion,
    link: 'https://orion.mrmehta.in',
    code: '',
  },
];

const Experiments = () => {
  return (
    <div className="flex justify-center">
      <div className="poppins container p-4 text-gray-900 min-h-screen animate-fadeIn">
        <div className="flex flex-col items-center mt-16">
          <img
            src={landing}
            alt="Landing Banner"
            className="w-full max-w-4xl g mb-8"
            loading="lazy"
          />
          <h1 className="text-2xl underline font-bold mb-4">Experiments</h1>
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {pages.map((page, index) => (
              <Card
                key={index}
                title={page.title}
                description={page.description}
                image={page.image}
                link={page.link}
                code={page.code}
                aria-label={`${page.title} - ${page.description}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experiments;
