import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import logo from '../assets/logo.svg';
import x from '../assets/x.webp';
import './navbar.css';

const DesktopRoute = ({ route, name }) => {
  return (
    <Link
      to={route}
      className="text-gray-700 text-sm hover:text-gray-600 transition-colors duration-300 hover:underline"
      aria-label={name}
    >
      {name}
    </Link>
  );
};

const MobileRoute = ({ route, name, setIsOpen }) => {
  return (
    <Link
      to={route}
      className="block text-gray-700 text-sm hover:text-gray-600 transition-colors duration-300 p-4"
      aria-label={name}
      onClick={() => setIsOpen(false)}
    >
      {name}
    </Link>
  );
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="poppins bg-white p-4 fixed w-full z-10">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold" aria-label="Tailwine Home">
          <div className="flex">
            <img src={logo} alt="logo" className="h-8 w-8 mr-2" loading="lazy" />
            <span>Tailwine</span>
          </div>
        </Link>
        <div className="hidden md:flex space-x-6">
          <DesktopRoute route="/services" name="Services" />
          <DesktopRoute route="/oss" name="Open Source" />
          <DesktopRoute route="/experiments" name="Experiments" />
          <DesktopRoute route="/contact" name="Contact Us" />
          <div className="flex">
            <a href="https://x.com/tailwine_studio" target="_blank" rel="noopener noreferrer">
              <img className="h-6 w-6 rounded-md ml-12 mr-2" src={x} alt="X" />
            </a>
          </div>
        </div>
        <button
          onClick={toggleMenu}
          className="md:hidden text-gray-700 focus:outline-none"
          aria-label="Toggle menu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
            />
          </svg>
        </button>
      </div>
      <CSSTransition in={isOpen} timeout={300} classNames="menu" unmountOnExit>
        <div className="md:hidden bg-white">
          <MobileRoute route="/services" name="Services" setIsOpen={setIsOpen} />
          <MobileRoute route="/oss" name="Open Source" setIsOpen={setIsOpen} />
          <MobileRoute route="/experiments" name="Experiments" setIsOpen={setIsOpen} />
          <MobileRoute route="/contact" name="Contact Us" setIsOpen={setIsOpen} />
        </div>
      </CSSTransition>
    </nav>
  );
};

export default Navbar;
