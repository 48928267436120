import React, { useState } from 'react';
import initialize from '../utils/supabase';
import contact from '../assets/contact.png';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const supabase = await initialize();
    setError(null);
    setSuccess(false);

    const { name, email, message } = formData;

    if (!name || !email || !message) {
      setError('All fields are required.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Invalid email address.');
      return;
    }

    setIsSubmitting(true);

    const { error } = await supabase
      .from('form')
      .insert([{ name: name.trim(), email: email.trim().toLowerCase(), message: message.trim() }]);

    setIsSubmitting(false);

    if (error) {
      setError('An error occurred while submitting the form. Please try again.');
    } else {
      setSuccess('Your message has been sent successfully.');
      setFormData({ name: '', email: '', message: '' });
    }
  };

  return (
    <div className="flex justify-center min-h-screen">
      <div className="poppins container p-4 text-gray-900 flex flex-col md:flex-row items-center justify-center animate-fadeIn">
        <div className="flex justify-center md:justify-start w-full md:w-1/2">
          <img
            src={contact}
            alt="Contact Us"
            className="w-3/4 md:w-full lg:w-2/3 mb-8 md:mb-0"
            loading="lazy"
          />
        </div>
        <div
          className="p-8 w-full max-w-lg mt-8 md:mt-0"
          role="form"
          aria-labelledby="contact-title"
        >
          <h1 id="contact-title" className="text-2xl font-bold text-center">
            Contact the Team
          </h1>
          <h2 className="font-bold mb-8 text-center underline">
            <a href="mailto:team@tailwine.in" target="_blank" rel="noreferrer">
              team@tailwine.in →
            </a>
          </h2>
          <p className="text-md text-gray-700 mb-6 text-center">
            Have any questions or feedback? We&apos;d love to hear from you!
          </p>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                className="text-sm w-full px-4 py-2 border-black border border-t-0 border-l-0 border-r-0 focus:outline-none focus:ring-1 focus:ring-black focus:border-b-0"
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                aria-required="true"
                placeholder="Name"
              />
            </div>
            <div className="mb-4">
              <input
                className="text-sm w-full px-4 py-2 border-black border border-t-0 border-l-0 border-r-0 focus:outline-none focus:ring-1 focus:ring-black focus:border-b-0"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                aria-required="true"
                placeholder="Email"
              />
            </div>
            <div className="">
              <textarea
                className="text-sm w-full px-4 py-2 border-black border border-t-0 border-l-0 border-r-0 focus:outline-none focus:ring-1 focus:ring-black focus:border-b-0"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="1"
                required
                aria-required="true"
                placeholder="Message"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full text-sm hover:bg-white hover:text-black hover:border-black border-white bg-black text-white border px-4 py-2 mt-4"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting' : 'Send'}
            </button>
            {error && (
              <p
                className="text-red-500 flex justify-center text-sm mt-2"
                style={{ textAlign: 'center' }}
              >
                {error}
              </p>
            )}
            {success && (
              <p
                className="text-green-500 flex justify-center text-sm mt-2"
                style={{ textAlign: 'center' }}
              >
                {success}
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
